import { useNavigate } from "react-router-dom"
import biyidp from "../images/biyid.png";

const Sidebar = () => {
  const navigate = useNavigate()
  const handleNavigate = (page) => navigate(`/${page}`);
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    navigate('/');
  };  

  return (
    <div className="side-bar">
    <div className="dp-space" onClick={() => handleNavigate('Portfoliopage')}>
      <figure>
        <img src={biyidp} alt="" />
      </figure>
      <h3>Hello 'Biyi</h3>
    </div>
    <section>
      <div className='sidebar-indicator' onClick={() => handleNavigate('Portfoliopage')}>
        Portfolio
      </div>
      <div onClick={() => handleNavigate('Lightpages')}>Light Pages</div>
      <div onClick={() => handleNavigate('Products')}>Products</div>
      <div onClick={() => handleNavigate('Clients')}>Clients</div>
      <div onClick={handleLogout} className="logout-button">Logout</div>
    </section>
  </div>
  )
}

export default Sidebar