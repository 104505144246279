import React, { useState, useEffect } from "react";
import pcimage from "../images/Component 40.png";
import axios from "axios";
import Sidebar from "../components/Sidebar";
import toast from "react-hot-toast";
import LoadingSpinner from "../components/Preloader/LoadingSpinner";
import { TEST_API } from "../App";

export default function Portfoliopage() {
  const [modalActive, setModalActive] = useState(false);
  const [modal2Active, setModal2Active] = useState(false);
  const [isActivedel, setIsActivedel] = useState(false);
  const [fileSections, setFileSections] = useState([{ id: Date.now() }]);
  const [portfolioItems, setPortfolioItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [banner, setBanner] = useState({});
  const [loading, setLoading] = useState(false); // Loading state for form submission
  const [processing, setProcessing] = useState({
    uploadBanner: false,
    fetchBanner: false,
    removeBanner: false,
  }); // Loading state for banner

  const fetchPortfolio = async () => {
    setProcessing((prev) => ({ ...prev, getPortfolio: true }));
    axios
      .get(`${TEST_API}/get-all-portfolio`) // replace with your actual endpoint
      .then((response) => {
        setPortfolioItems(response.data.portfolioItems);
        setFilteredItems(response.data.portfolioItems);
        setProcessing((prev) => ({ ...prev, getPortfolio: false }));
      })
      .catch((error) => {
        console.error("Error fetching portfolio items:", error);
        setProcessing((prev) => ({ ...prev, getPortfolio: false }));
      });
  };
  useEffect(() => {
    fetchPortfolio();
    getBanner();
  }, []);
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);

    const filtered = portfolioItems.filter((item) =>
      item.title.toLowerCase().includes(value)
    );
    setFilteredItems(filtered);
  };

  const handleAddContentClick = () => {
    setModalActive(true);
    setModal2Active(false); // Deactivate modal2 if active
  };

  const handlePCNameClick = (item) => {
    setModal2Active(true);
    setModalActive(false); // Deactivate modal if active
    setSelectedItem(item);
  };

  const handleModalClick = (e) => {
    if (
      e.target.classList.contains("modal-bg") ||
      e.target.classList.contains("ppm-form") ||
      e.target.classList.contains("close-btn-modal")
    ) {
      setModalActive(false);
    }
  };

  const handleModal2Click = (e) => {
    if (
      e.target.classList.contains("modal2-bg") ||
      e.target.classList.contains("close-btn-modal")
    ) {
      setModal2Active(false);
    }
    setSelectedItem(null);
  };

  const addFileSection = () => {
    setFileSections([...fileSections, { id: Date.now() }]);
  };

  const removeFileSection = (idToRemove) => {
    setFileSections((prevFileSections) =>
      prevFileSections.filter((section) => section.id !== idToRemove)
    );
  };

  const handleMediaChange = async (event) => {
    const file = event.target.files[0];
    setProcessing((prev) => ({ ...prev, uploadBanner: true }));

    if (file) {
      const fileType = file.type.split("/")[0];

      const formData = new FormData();
      formData.append("type", fileType);
      formData.append("image", file);

      try {
        const res = await axios.post(`${TEST_API}/createBanner`, formData);
        getBanner();
        toast.success(res.data.message || "Upload successful");
      } catch (error) {
        console.error(error);
        toast.error(error.message || "Failed to upload");
      } finally {
        setProcessing((prev) => ({ ...prev, uploadBanner: false }));
      }
    }
  };

  const getBanner = async () => {
    setProcessing((prev) => ({ ...prev, fetchBanner: true }));
    try {
      const res = await axios.get(`${TEST_API}/getAllPortfolioItems`);
      setBanner(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setProcessing((prev) => ({ ...prev, fetchBanner: false }));
    }
  };

  const deleteBanner = async (id) => {
    setProcessing((prev) => ({ ...prev, removeBanner: true }));
    try {
      const res = await axios.delete(`${TEST_API}/deleteBanner/${id}`);
      toast.success(res.data.message || "Deleted sucessfully");
      getBanner();
    } catch (error) {
      console.error(error);
      toast.error(error.message || "Failed to delete banner");
    } finally {
      setProcessing((prev) => ({ ...prev, removeBanner: false }));
    }
  };

  const handleYesClick = () => {
    // Close all modals
    setModalActive(false);
    setModal2Active(false);
    setIsActivedel(false);
  };

  const handleNoClick = () => {
    // Close only delete confirmation modal
    setIsActivedel(false);
  };

  const [formData, setFormData] = useState({
    title: "",
    type: "",
    alt: "",
    innerPageHeadlines: "",
    innerPageContent: "",
    alt2: "",
    buttonTitle: "",
    buttonLink: "",
    image: null,
    image2: null,
    innerPageImage: null,
    innerPage: [],
  });

  const handleFileChange = (e, sectionId) => {
    const file = e.target.files[0];
    setFormData((prev) => ({
      ...prev,
      innerPage: [...prev.innerPage, file],
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true when submitting

    const finalData = new FormData();
    finalData.append("title", formData.title);
    finalData.append("type", formData.type);
    finalData.append("alt", formData.alt);
    finalData.append("innerPageHeadlines", formData.innerPageHeadlines);
    finalData.append("innerPageContent", formData.innerPageContent);
    finalData.append("alt2", formData.alt2);
    finalData.append("buttonTitle", formData.buttonTitle);
    finalData.append("buttonLink", formData.buttonLink);
    finalData.append("image", formData.image);
    finalData.append("image2", formData.image2);
    finalData.append("innerPageImage", formData.innerPageImage);

    // Append innerPage files
    formData.innerPage.forEach((file) => {
      finalData.append("innerPage", file);
    });

    try {
      const response = await axios.post(
        `${TEST_API}/createPortfolio`,
        finalData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
      fetchPortfolio();
      setFormData();
      setModalActive(false);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const [selectedPageId, setSelectedPageId] = useState(null);
  // Open modal and set the selected page ID
  const handleDeleteClick = (pageId) => {
    setSelectedPageId(pageId);
    setIsActivedel(true); // Open modal
  };

  const handleDeleteModal = async () => {
    try {
      setLoading(true); // Start loading when the delete process begins

      const token = localStorage.getItem("token");
      // Make the delete request to the API using the selected ID
      const response = await axios.delete(
        `${TEST_API}/delete-portfolio/${selectedPageId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        console.log("Delete successful", response.data);
        fetchPortfolio();
      }
    } catch (error) {
      console.error("Error deleting content:", error);
    } finally {
      // Hide the modal and stop loading
      setLoading(false);
      setIsActivedel(false);
      setSelectedPageId(null);
    }
  };

  return (
    <div className="portfolio-page">
      <Sidebar />
      <main>
        <div className="add-content-btn" onClick={handleAddContentClick}>
          + Add content
        </div>

        <div className="upload-banner-pic-space">
          <div className="media-upload-box">
            {processing.fetchBanner ||
            processing.removeBanner ||
            processing.uploadBanner ? (
              <LoadingSpinner />
            ) : banner?.portfolioCategory?.length > 0 ? (
              <>
                <input
                  type="button"
                  value="Delete"
                  onClick={() => deleteBanner(banner.portfolioCategory[0]._id)}
                  className="media-edit-btn"
                />
                <img
                  src={banner.portfolioCategory[0].image}
                  alt="Uploaded Preview"
                  className="media-preview"
                />
              </>
            ) : (
              <>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleMediaChange}
                  className="media-input"
                />
                <p className="media-placeholder">Upload Banner Image</p>
              </>
            )}{" "}
          </div>
        </div>

        <input
          type="search"
          placeholder="Search content"
          className="search-input"
          value={searchText}
          onChange={handleSearch}
        />

        <div className="portfolio-contents">
          {processing.getPortfolio ? (
            <LoadingSpinner />
          ) : (
            filteredItems.map((item) => (
              <div className="portfolio-content" key={item._id}>
                <figure className="pc-image">
                  <img src={item.image} alt={item.alt} />
                </figure>
                <h4
                  className="pc-text-name"
                  onClick={() => handlePCNameClick(item)}
                >
                  {item.title}
                </h4>
                <span
                  className="cancel-pc-item"
                  onClick={() => handleDeleteClick(item._id)}
                >
                  X
                </span>
              </div>
            ))
          )}
        </div>
      </main>
      {modalActive && (
        <div className="modal-bg">
          <div className="portfoliopage-modal">
            <div className="close-btn-modal" onClick={handleModalClick}>
              x
            </div>
            <form onSubmit={handleSubmit} className="ppm-form">
              <div className="form-subheading">
                <h2>Outer Page</h2>
                <span className="fs-line"></span>
              </div>
              <section className="form-input-sec">
                <input
                  type="text"
                  placeholder="Title"
                  name="title"
                  onChange={handleInputChange}
                />
                <div className="file-section">
                  <p>Upload Desktop Cover visual</p>
                  <input
                    type="file"
                    onChange={(e) =>
                      setFormData({ ...formData, image: e.target.files[0] })
                    }
                  />
                </div>
                <div className="file-section">
                  <p>Upload Mobile Cover visual</p>
                  <input
                    type="file"
                    onChange={(e) =>
                      setFormData({ ...formData, image2: e.target.files[0] })
                    }
                  />
                </div>
                <input
                  type="text"
                  placeholder="Alt text"
                  name="alt"
                  onChange={handleInputChange}
                />
              </section>
              <select
                name="type"
                id=""
                className="portfolio-select"
                onChange={handleInputChange}
              >
                <option value="" disabled selected hidden>
                  Portfolio type
                </option>
                <option value="Product Design">Product Design</option>
                <option value="Brand Identity & Strategy">
                  Brand Identity & Strategy
                </option>
              </select>
              <div className="form-subheading">
                <h2>Inner Page</h2>
                <span className="fs-line"></span>
              </div>
              <section className="form-input-sec">
                <div className="file-section">
                  <p>Upload Headline Cover Visual</p>
                  <input
                    type="file"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        innerPageImage: e.target.files[0],
                      })
                    }
                  />
                </div>
                <input
                  type="text"
                  placeholder="Enter Headline text"
                  name="innerPageHeadlines"
                  onChange={handleInputChange}
                />
                <textarea
                  name="innerPageContent"
                  placeholder="Enter content"
                  onChange={handleInputChange}
                ></textarea>
                <section className="section-container">
                  {fileSections.map((section) => (
                    <div key={section.id} className="file-section">
                      <p>Upload image/gif/video</p>
                      <input
                        type="file"
                        onChange={(e) => handleFileChange(e, section.id)}
                      />
                      <div
                        className="dlt-single-file-section"
                        onClick={() => removeFileSection(section.id)}
                      >
                        X
                      </div>
                    </div>
                  ))}
                  <div className="manipulation-btns">
                    <span className="mb-1" onClick={addFileSection}>
                      add
                    </span>
                  </div>
                </section>
                <input
                  type="text"
                  placeholder="button title"
                  name="buttonTitle"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  placeholder="button link"
                  name="buttonLink"
                  onChange={handleInputChange}
                />
              </section>
              <div className="form-btns">
                <button type="reset" onClick={handleDeleteClick}>
                  Delete
                </button>
                <button type="submit" disabled={loading}>
                  {loading ? "Publishing..." : "Publish"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {modal2Active && selectedItem && (
        <div className="modal2-bg">
          <div className="portfoliopage-modal2">
            <div className="close-btn-modal" onClick={handleModal2Click}>
              x
            </div>
            <form action="" className="ppm-form2">
              <div className="form-subheading">
                <h2>Outer Page</h2>
                <span className="fs-line"></span>
              </div>
              <section className="form-input-sec">
                <input
                  type="text"
                  placeholder="Edit Title"
                  defaultValue={selectedItem.title}
                />
                <div className="file-section">
                  <p>Edit Desktop visual banner</p>
                  <input type="file" />
                </div>
                <div className="file-section">
                  <p>Edit Desktop visual Cover</p>
                  <input type="file" />
                </div>
                <input
                  type="text"
                  placeholder="Edit Alt text"
                  defaultValue={selectedItem.alt}
                />
              </section>
              <br />
              <br />
              <div className="form-subheading">
                <h2>Inner Page</h2>
                <span className="fs-line"></span>
              </div>
              <section className="form-input-sec">
                <div className="file-section">
                  <p>Edit Headline Cover Visual</p>
                  <input type="file" />
                </div>
                <input
                  type="text"
                  placeholder="Edit Headline text"
                  defaultValue={selectedItem.innerPageHeadlines}
                />
                <textarea
                  placeholder="Edit content"
                  defaultValue={selectedItem.innerPageContent}
                ></textarea>
                {selectedItem.innerPage.map((inner, index) => (
                  <div key={inner._id} className="file-section">
                    <p>Edit image/gif/video {index + 1}</p>
                    <input type="file" />
                    <span className="del-iv">Delete</span>
                  </div>
                ))}
                <input
                  type="text"
                  placeholder="Edit Alt text"
                  defaultValue={selectedItem.alt2}
                />
                <input
                  type="text"
                  placeholder="Edit Button Title"
                  defaultValue={selectedItem.buttonTitle}
                />
                <input
                  type="text"
                  placeholder="Edit Button Link"
                  defaultValue={selectedItem.buttonLink}
                />
              </section>
              <div className="form-btns">
                <button type="reset" onClick={handleDeleteClick}>
                  Delete
                </button>
                <button type="submit">Update</button>
              </div>
            </form>
          </div>
        </div>
      )}
      <div className={`delete-modal-bg ${isActivedel ? "active" : ""}`}>
        <h1>Sure you want to delete content?</h1>
        <div className="dm-btns">
          <span className="dm-btn1" onClick={handleDeleteModal}>
            {loading ? "Deleting..." : "Yes"}
          </span>
          <span className="dm-btn2" onClick={handleNoClick}>
            No
          </span>
        </div>
      </div>
    </div>
  );
}
