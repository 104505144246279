import styles from "./loadingSpinner.css"

const LoadingSpinner = () => {
  return (
    <section style={{ width: "100%"}}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className={styles.spinner}></div>
      </div>
    </section>
  );
};

export default LoadingSpinner;
